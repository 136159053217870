import { createContext, Dispatch, SetStateAction } from 'react'

import { Sermon } from '@/models/Sermon'

import { SermonFilters } from './useSermonsState'

type SermonsContextProps = {
  displayedSermons: Sermon[]
  page: number
  pageCount: number
  filters: SermonFilters
  speakerOptions: string[]
  setPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<SermonFilters>>
}

export const SermonsContext = createContext<SermonsContextProps>({
  displayedSermons: [],
  page: 1,
  pageCount: 1,
  filters: {
    fromDate: null,
    toDate: null,
    title: '',
    speaker: '',
  },
  speakerOptions: [],
  setPage: () => null,
  setFilters: () => null,
})
