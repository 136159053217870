import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { PageCover } from '@/components/common/PageCover'
import { SermonsContext } from '@/components/resources/SermonsContext'
import { SermonsFilters } from '@/components/resources/SermonsFilters'
import { SermonsPagination } from '@/components/resources/SermonsPagination'
import { SermonsTable } from '@/components/resources/SermonsTable'
import { useSermonsState } from '@/components/resources/useSermonsState'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "sermons" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    sermons: allStrapiSermons(sort: { fields: [preached_on], order: DESC }) {
      nodes {
        strapiId
        preached_on
        title
        speaker
        audio_url
      }
    }
  }
`

const SermonsPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  const sermons = data.sermons.nodes.map(
    ({ strapiId, preached_on, ...rest }: any) => ({
      id: strapiId,
      preached_on: new Date(preached_on),
      ...rest,
    })
  )
  const sermonState = useSermonsState(sermons || [])

  return (
    <>
      <SEO title="Sermons" />
      <PageCover image={image} title="Sermons" />
      <PageContainer>
        <SermonsContext.Provider value={sermonState}>
          <SermonsFilters />
          <SermonsTable />
          <SermonsPagination />
        </SermonsContext.Provider>
      </PageContainer>
    </>
  )
}

export default SermonsPage
