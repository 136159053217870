import { useState } from 'react'

import { Sermon } from '@/models/Sermon'

const PAGE_SIZE = 20

export type SermonFilters = {
  fromDate: string | null
  toDate: string | null
  title: string
  speaker: string
}

export function useSermonsState(sermons: Sermon[]) {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<SermonFilters>({
    fromDate: null,
    toDate: null,
    title: '',
    speaker: '',
  })

  const filteredSermons = sermons
    .filter(
      (s) =>
        new Date(s.preached_on).setHours(0, 0, 0, 0) >=
        new Date(filters.fromDate ?? '1970-01-01').getTime()
    )
    .filter(
      (s) =>
        new Date(s.preached_on).setHours(0, 0, 0, 0) <=
        new Date(filters.toDate ?? '2099-12-31').getTime()
    )
    .filter((s) =>
      filters.title.length > 0
        ? s.title.match(new RegExp(filters.title, 'ig'))
        : true
    )
    .filter((s) =>
      filters.speaker.length > 0 ? s.speaker === filters.speaker : true
    )
  const displayedSermons = filteredSermons.slice(
    (page - 1) * PAGE_SIZE,
    page * PAGE_SIZE
  )
  const pageCount = Math.ceil(filteredSermons.length / PAGE_SIZE)
  const speakerOptions = Array.from(new Set(sermons.map((s) => s.speaker)))

  return {
    displayedSermons,
    page,
    pageCount,
    filters,
    speakerOptions,
    setPage,
    setFilters,
  }
}
