import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import React, { FC, useContext } from 'react'

import { SermonsContext } from './SermonsContext'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  filter: {
    '&:not(:last-child)': {
      marginRight: spacing(4),
    },
    [breakpoints.down('sm')]: {
      width: `calc((100% - ${spacing(2)}px) / 2)`,
      '&:not(:last-child)': {
        marginRight: 0,
      },
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dateFilter: {
    flex: 'none',
  },
  textFilter: {
    flex: 3,
    '& input[type="search"]::-webkit-search-cancel-button, & input[type="search"]::-webkit-search-decoration':
      {
        appearance: 'none',
      },
    [breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
  selectFilter: {
    flex: 2,
    [breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
}))

export const SermonsFilters: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { filters, speakerOptions, setFilters } = useContext(SermonsContext)

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        margin={isSmallScreen ? 'dense' : 'normal'}
        type="date"
        label="From"
        value={filters.fromDate}
        onChange={(event) =>
          setFilters((prev) => ({
            ...prev,
            fromDate: event.target.value || null,
          }))
        }
        InputLabelProps={{
          shrink: true,
        }}
        className={clsx(classes.filter, classes.dateFilter)}
      />
      <TextField
        variant="outlined"
        margin={isSmallScreen ? 'dense' : 'normal'}
        type="date"
        label="To"
        value={filters.toDate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) =>
          setFilters((prev) => ({
            ...prev,
            toDate: event.target.value || null,
          }))
        }
        className={clsx(classes.filter, classes.dateFilter)}
      />
      <TextField
        variant="outlined"
        margin={isSmallScreen ? 'dense' : 'normal'}
        type="search"
        label="Sermon Title"
        placeholder="Search by title"
        value={filters.title}
        onChange={(event) =>
          setFilters((prev) => ({
            ...prev,
            title: event.target.value,
          }))
        }
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => setFilters((prev) => ({ ...prev, title: '' }))}
                disabled={filters.title.length === 0}
              >
                {filters.title.length > 0 ? <Clear /> : <Search />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={clsx(classes.filter, classes.textFilter)}
      />
      <Autocomplete
        value={filters.speaker}
        onChange={(_event, value) =>
          setFilters((prev) => ({
            ...prev,
            speaker: value ?? '',
          }))
        }
        options={speakerOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin={isSmallScreen ? 'dense' : 'normal'}
            label="Speaker"
            placeholder="Filter by speaker"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        className={clsx(classes.filter, classes.selectFilter)}
      />
    </div>
  )
}
