import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { FC, useContext } from 'react'

import { SermonsContext } from './SermonsContext'
import { SermonsTableRow } from './SermonsTableRow'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    tableContainer: {
      width: 'auto',
      margin: spacing(4, -1),
      padding: spacing(1),
    },
    tableHead: {
      backgroundColor: palette.primary.main,
    },
    tableHeadCell: {
      paddingTop: spacing(1.25),
      paddingBottom: spacing(1.25),
      color: palette.primary.contrastText,
      fontWeight: typography.fontWeightBold,
      fontSize: '1rem',
      letterSpacing: '0.08875em',
      textTransform: 'uppercase',
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        padding: spacing(0.5, 1),
      },
      [breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      },
    },
    spacer: {
      height: spacing(2),
    },
    placeholder: {
      boxShadow: '0 1px 6px rgba(189, 189, 189, 0.5)',
      padding: spacing(8, 2),
      textAlign: 'center',
      verticalAlign: 'middle',
    },
  })
)

export const SermonsTable: FC = () => {
  const classes = useStyles()
  const { displayedSermons } = useContext(SermonsContext)

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Date</TableCell>
            <TableCell className={classes.tableHeadCell}>Title</TableCell>
            <TableCell className={classes.tableHeadCell}>Speaker</TableCell>
            <TableCell className={classes.tableHeadCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedSermons.length > 0 ? (
            displayedSermons.map((sermon) => (
              <SermonsTableRow key={sermon.id} sermon={sermon} />
            ))
          ) : (
            <>
              <TableRow className={classes.spacer} />
              <TableRow>
                <TableCell colSpan={4} className={classes.placeholder}>
                  No sermons match your search.
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
