import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Pagination, PaginationItem } from '@material-ui/lab'
import React, { FC, useContext } from 'react'

import { SermonsContext } from './SermonsContext'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: spacing(10),
      [breakpoints.down('md')]: {
        marginTop: spacing(8),
      },
      [breakpoints.down('sm')]: {
        marginTop: spacing(6),
      },
      [breakpoints.down('xs')]: {
        marginTop: spacing(4),
      },
    },
    selectedItem: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold,
      textDecoration: 'underline',
    },
  })
)

export const SermonsPagination: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { page, pageCount, setPage } = useContext(SermonsContext)

  return (
    <div className={classes.root}>
      <Pagination
        page={page}
        count={pageCount}
        onChange={(_event, value) => setPage(value)}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{ selected: classes.selectedItem }}
          />
        )}
        shape="rounded"
        size={isSmallScreen ? 'small' : 'large'}
        showFirstButton
        showLastButton
      />
    </div>
  )
}
