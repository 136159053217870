import {
  Link,
  makeStyles,
  TableCell,
  TableRow,
  TableRowProps,
} from '@material-ui/core'
import { Videocam, VolumeUp } from '@material-ui/icons'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import React, { FC } from 'react'

import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { Sermon } from '@/models/Sermon'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  spacer: {
    height: spacing(2),
  },
  row: {
    boxShadow: '0 1px 6px rgba(189, 189, 189, 0.5)',
    backgroundColor: palette.background.paper,
  },
  cell: {
    border: 0,
    fontSize: '1rem',
    letterSpacing: '0.03125em',
    [breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      padding: spacing(0.5, 1),
    },
    [breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  mediaLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    letterSpacing: '0.089286em',
    color: palette.primary.main,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  linkIcon: {
    marginRight: spacing(1),
    fontSize: 'inherit',
  },
  linkText: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

type SermonsTableRowProps = TableRowProps & {
  sermon: Sermon
}

export const SermonsTableRow: FC<SermonsTableRowProps> = ({
  className,
  sermon,
  ...rest
}) => {
  const classes = useStyles()
  const { play } = useAudioPlayer()

  return (
    <>
      <TableRow className={classes.spacer}></TableRow>
      <TableRow className={clsx(classes.row, className)} {...rest}>
        <TableCell className={classes.cell}>
          {DateTime.fromJSDate(sermon.preached_on).toFormat('dd.MM.yyyy')}
        </TableCell>
        <TableCell className={classes.cell}>{sermon.title}</TableCell>
        <TableCell className={classes.cell}>{sermon.speaker}</TableCell>
        <TableCell className={classes.cell}>
          {sermon.audio_url && (
            <Link
              className={classes.mediaLink}
              color="textPrimary"
              underline="always"
              onClick={() => play(sermon.title, sermon.audio_url)}
            >
              <VolumeUp className={classes.linkIcon} />
              <span className={classes.linkText}>Listen</span>
            </Link>
          )}
          {/* {sermon.video_url && (
            <Link
              href={sermon.video_url}
              target="_blank"
              rel="noreferrer"
              className={classes.mediaLink}
              color="textPrimary"
              underline="always"
            >
              <Videocam className={classes.linkIcon} />
              <span className={classes.linkText}>Watch</span>
            </Link>
          )} */}
        </TableCell>
      </TableRow>
    </>
  )
}
